import styles from './AlertDetailsPage.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {Fragment, useEffect, useState} from 'react';
import {perDS} from '../../datasource/WebDS';
import {AccessUtil, TimeUtil, ActiveEventDetails, AlertDetails, EventLogItem, PerentieAlertActionTypeEnum, UserBasicDetails} from '@perentie/common';
import { Cell, Label, Row, Table} from '@systemic-design-framework/react';
import {durationBetween} from '@perentie/common';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/authenticationSlice';
import Tabs from '../../components/Tab/Tabs';
import {
  IoAddCircle,
  IoClipboard, IoCopyOutline,
  IoGlobeOutline,
  IoBuildOutline,
  IoRemoveCircle,
  IoTime
} from 'react-icons/io5';
import PerentieModal from '../../components/Modal/Modal';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import { roundTimeToLastQuarterHour } from '../../utils/DateTimeUtils';

interface SupportTime {
  startTime: Date;
  endTime: Date;
  duration: number;
}

function AlertDetailsPage(){
  const navigate = useNavigate();
  const {alertId} = useParams();
  const [details, setDetails] = useState<AlertDetails>();
  const [summary, setSummary] = useState<ActiveEventDetails[]>([]);
  const [userOpts, setUserOpts] = useState<UserBasicDetails[]>([]);
  const [showAssign, setShowAssign] = useState(false);
  const [showAlertReference, setShowAlertReference] = useState(false);
  const [showCloseSummary, setShowCloseSummary] = useState(false);

  const [selectedUser, setSelectedUser] = useState<string>();
  const [saving, setSaving] = useState(false);

  const [showActions, setShowActions] = useState<boolean>(false);
  const [showSupportTime, setShowSupportTime] = useState<boolean>(false);
  const [showEditSupportTime, setShowEditSupportTime] = useState<boolean>(false);
  const [selectedActionEventId, setSelectedActionEventId] = useState<string>('');
  const [supportTime, setSupportTime] = useState<SupportTime>({startTime: new Date(), endTime: new Date(), duration: 0});

  const [actionEvents, setActionEvents] = useState<EventLogItem[]>([]);
  const [actionEventsFilter, setActionEventsFilter] = useState<string>('');
  const [filteredActionEvents, setFilteredActionEvents] = useState<EventLogItem[]>([]);
  const [comment, setComment] = useState<string>('');
  const [reference, setReference] = useState<string>('');
  const [showComment, setShowComment] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);
  const [addedActionEvent, setAddedActionEvent] = useState(false);
  const [eventLog, setEventLog] = useState<EventLogItem[]>([]);

  const [showCLoseEventDialog, setShowCloseEventDialog] = useState(false);
  const [closeSummary, setCloseSummary] = useState('');

  const currentUser = useSelector(selectUser);
  const accessUtil = new AccessUtil(currentUser);
  const timeUtil = new TimeUtil();
  const canAssign = accessUtil.isSystemAdmin() || accessUtil.hasRoleType('CLIENT_ADMIN') || accessUtil.hasRoleType('RESPONDER');

  const actions = [
    {
      label: "Add Comment",
      icon: <IoClipboard/>,
      type: "COMMENT",
      click: () => {
        showActionsClose();
        showCommentClick();
      }
    },
    {
      label: "Add Support Time",
      icon: <IoTime/>,
      type: "SUPPORT_TIME",
      click: () => {
        showActionsClose();
        showSupportTimeClick();
      }
    }
  ];

  useEffect(() => {
    Promise.all([perDS.getAlertDetails(alertId!), perDS.getAlertEventSummary(alertId!)]).then( res => {
      const ds = res[0];
      ds.eventLog = ds.eventLog!.reverse();
      const resDetails = res[0];
      setDetails(resDetails);
      setCloseSummary(resDetails?.closeSummary ?? '');
      const actevnts = ds.eventLog.filter(item => item.type === "COMMENT" || item.type === "SUPPORT_TIME")
      setActionEvents(actevnts);
      setFilteredActionEvents(actevnts);
      setSummary(res[1]);
    }).catch(reason => {
      console.log(' catch', reason);
    })
    // @ts-ignore
    perDS.getUserHandlingOptions(alertId, "ASSIGN").then( res => {
      console.log(' handling options: ', res);
      setUserOpts(res);
    }).catch( reason => {
      console.log(' handling options fail', reason);
    })
  }, []);

  useEffect(() => {
    if (actionEventsFilter == '') {
      setFilteredActionEvents(actionEvents);
    }
    if (actionEventsFilter == 'COMMENT') {
      setFilteredActionEvents(actionEvents.filter(evnt => evnt.type == "COMMENT"));
    }
    if (actionEventsFilter == 'SUPPORT_TIME') {
      setFilteredActionEvents(actionEvents.filter(evnt => evnt.type == "SUPPORT_TIME"));
    }
  }, [actionEvents, actionEventsFilter]);

  useEffect(() => {
    if (details) {
      setEventLog(sortEventsNewestFirst(details?.eventLog!));
      const actevnts = sortEventsNewestFirst(details?.eventLog?.filter(item => item.type === "COMMENT" || item.type === "SUPPORT_TIME")!);
      setActionEvents(actevnts!);
      setFilteredActionEvents(actevnts!);
      setAddedActionEvent(false);
    }
  }, [details]);

  const selectOpts = userOpts.map( (user, idx) => {
    return (
      <option key={idx} value={user.objectId}>{user.username}</option>
    )
  })

  const eventSummary = summary.map( (evt, index) => {
    let classes = [styles['count']]
    if (evt.resolvedTime == null){
      classes.push(styles['countActive']);
    }else{
      classes.push(styles['countEnded']);
    }
    return (
      <div key={index + ' ' + evt.objectId}>
        <span className={classes.join(' ')}>{evt.messageCount}</span> {evt.lastMessage?.title}
      </div>
    )
  });

  const showCloseAlertClick = () => {
    setShowCloseEventDialog(true);
  }

  const closeAlert = (item: AlertDetails, summary: string) => {
    // console.log('close alert', item, summary);
    // return;
    perDS.closeAlert(item.objectId!, summary).then(res => {
      navigate('/alerts');
    });
  }

  const alertHandle = (type: string) => {
    switch(type) {
      case 'handle':
        perDS.handleAlert(details?.objectId!)
        .then(res => {
          setEventLog([]);
          setDetails(res);
        })
        .catch(err => {
          console.error('assignAlert fail ', err);
        });
        break;
      case 'unhandle':
        perDS.unHandleAlert(details?.objectId!)
        .then(res => {
          setEventLog([]);
          setDetails(res);
        })
        .catch(err => {
          console.error('unHandleAlert fail ', err);
        });
        break;
    }
  }

  const showAssignClick = () => {
    setShowAssign(true);
  }
  const showAssignClose = () => {
    setShowAssign(false);
  }
  const selectChange = (val: any) => {
    console.log(' select change', val.target.value);
    setSelectedUser(val.target.value);
  }

  const showActionsClick = () => {
    setShowActions(true);
  }

  const showActionsClose = () => {
    setShowActions(false);
  }

  const showSupportTimeClick = () => {
    setSupportTime({startTime: roundTimeToLastQuarterHour(new Date()), endTime: roundTimeToLastQuarterHour(new Date()), duration: 0});
    setComment('');
    setShowSupportTime(true);
  }

  const showSupportTimeClose = () => {
    setShowSupportTime(false);
    setShowEditSupportTime(false);
    setSelectedActionEventId('');
  }

  const showAlertReferenceClick = () => {
    setReference(details?.clientReference ? details.clientReference : '');
    setShowAlertReference(true);
  }

  const showAlertReferenceClose = () => {
    setShowAlertReference(false);
    setReference('');
  }

  const showCommentClick = () => {
    setComment('');
    setShowComment(true);
  }
  const showCommentClose = () => {
    setShowComment(false);
    setShowEditComment(false);
    setSelectedActionEventId('');
  }

  const showCLoseEventDialogClose = () => {
    setShowCloseEventDialog(false);
    setCloseSummary('');
  }

  const confirmReference = () => {
    setSaving(true);
    perDS.updateAlertReference(alertId!, reference)
    .then(res => {
      setDetails(res);
      setSaving(false);
      showAlertReferenceClose();
    })
    .catch(err => {
      console.error('failed updateAlertReference: ', err);
      alert(err);
      setSaving(false);
      showAlertReferenceClose();
    })
  }

  const updateCloseSummary = () => {
    setSaving(true);
    perDS.updateAlertCloseSummary(alertId!, closeSummary)
    .then(res => {
      setDetails(res);
      setCloseSummary(res.closeSummary!);
      setSaving(false);
      setShowCloseSummary(false);
    })
    .catch(err => {
      console.error('failed updateAlertCloseSummary: ', err);
      alert(err);
      setSaving(false);
      setShowCloseSummary(false);
    })
  }

  const confirmAssign = () => {
      setSaving(true);
      perDS.assignAlert(alertId!, selectedUser!).then( res => {
        res.eventLog = res.eventLog?.reverse();
        setDetails(res);
        setSaving(false);
        setShowAssign(false);
      }).catch( reason => {
        setSaving(false);
        console.log('assignAlert fail ', reason);
        window.alert("Assigning user failed " + reason);
      })
  }

  const confirmAction = (type: PerentieAlertActionTypeEnum) => {
    setSaving(true);
    perDS.addOrUpdateActionToAlert(
      alertId!,
      selectedActionEventId!,
      comment!,
      type,
      type == "SUPPORT_TIME" ? supportTime.startTime : undefined!,
      type == "SUPPORT_TIME" ? supportTime.endTime : undefined!
    ).then(res => {
      setDetails(res);
      setSaving(false);
      setAddedActionEvent(true);

      switch(type) {
        case "COMMENT":
          showCommentClose();
          break;
        case "SUPPORT_TIME":
          showSupportTimeClose();
          break;
      }
    }).catch(err => {
      setSaving(false);
      console.error('fail: ', err);
      window.alert("Adding action failed: \n" + err);
    });
  }

  const updateAction = (log: EventLogItem) => {
    if (log.userId != currentUser.objectId) {
      return;
    }

    switch(log.type) {
      case "COMMENT":
        setSelectedActionEventId(log.objectId!);
        setComment(log.description!);
        setShowEditComment(true);
        break;
      case "SUPPORT_TIME":
        perDS.getAlertAction(log.objectId!)
        .then(res => {
          setSupportTime({startTime: res.startTime!, endTime: res.endTime!, duration: (res.endTime?.getTime()!-res.startTime?.getTime()!)/1000/60});
          setComment(res.info!);
          setSelectedActionEventId(log.objectId!);
          setShowEditSupportTime(true);
        })
        .catch(err => {
          console.error('failed getAlertAction: ', err);
          window.alert("Retreiving alert action failed: \n" + err);
        })
        break;
    }
  }

  const handleNewComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event?.target?.value);
  };

  const sortEventsNewestFirst = (arr: EventLogItem[]) => {
    return arr.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
  }

  const supportTimeChange = (side: 'start' | 'end', type: 'increment' | 'decrement') => {
    var step = 15;
    var difference = (step * 60) * 1000;

    var start: Date = supportTime.startTime;
    var end: Date = supportTime.endTime;

    switch(side) {
      case 'start':
        switch(type) {
          case 'increment':
            start = new Date(supportTime.startTime.getTime()+difference);
            end = supportTime.startTime.getTime() == supportTime.endTime.getTime() ? new Date(supportTime.endTime.getTime()+difference) : supportTime.endTime
            setSupportTime({
              ...supportTime,
              startTime: start,
              endTime: end,
              duration: (end.getTime()-start.getTime())/1000/60
            });
            break;
          case 'decrement':
            start = new Date(supportTime.startTime.getTime()-difference);
            setSupportTime({
              ...supportTime,
              startTime: start,
              duration: (end.getTime()-start.getTime())/1000/60
            });
            break;
        }
        break;
      case 'end':
        switch(type) {
          case 'increment':
            end = new Date(supportTime.endTime.getTime()+difference);
            setSupportTime({
              ...supportTime,
              endTime: end,
              duration: (end.getTime()-start.getTime())/1000/60
            });
            break;
          case 'decrement':
            start = supportTime.startTime.getTime() == supportTime.endTime.getTime() ? new Date(supportTime.startTime.getTime()-difference) : supportTime.startTime;
            end = new Date(supportTime.endTime.getTime()-difference);
            setSupportTime({
              ...supportTime,
              startTime: start,
              endTime: end,
              duration: (end.getTime()-start.getTime())/1000/60
            });
            break;
        }
        break;
    }
  }

  const supportDateChange = (date: Date, type: 'increment' | 'decrement') => {
    const newStartTime = new Date(supportTime.startTime);
    const newEndTime = new Date(supportTime.endTime);

    if (type === 'increment') {
      newStartTime.setDate(newStartTime.getDate() + 1);
      newEndTime.setDate(newEndTime.getDate() + 1);
    } else if (type === 'decrement') {
      newStartTime.setDate(newStartTime.getDate() - 1);
      newEndTime.setDate(newEndTime.getDate() - 1);
    }

    setSupportTime({
      ...supportTime,
      startTime: newStartTime,
      endTime: newEndTime,
      duration: (newEndTime.getTime() - newStartTime.getTime()) / 1000 / 60
    });
  }
  const copyText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      window.alert('Failed to copy');
    }
  }

  const renderItem = (item: EventLogItem, index: number) =>{
    return (
      <Row key={index} striped={index % 2 !=0}>
        <Cell>{item.timestamp?.toLocaleString()}</Cell>
        <Cell>{item.type}{(item.type === "EVENT" ? ' - ' + item.tag : '')}</Cell>
        <Cell>{item.title}</Cell>
        <Cell>{item.type != 'EVENT' ? item.tag : ''}</Cell>
      </Row>
    )
  }

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Time</Cell>
        <Cell>Type</Cell>
        <Cell>Info</Cell>
        <Cell>Component State</Cell>
      </Row>
    );
  };

  const end = details?.ended == null ? new Date()  : details.ended!;
  const dur = durationBetween(details?.created, end);

  const ActionEventsList = () => {
    return filteredActionEvents.map( item => {
      return (
        <div key={item.objectId} className={styles["action-event"]}>
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12}}>
                  {item.type == "COMMENT" ? (
                    <IoClipboard color='#6AAD00'/>
                  ) : (
                    <IoTime color='#6AAD00'/>
                  )}
                  {item.userId == currentUser.objectId && (
                    <IoBuildOutline color='#808080' size={18} className={styles["clickable"]} onClick={() => {updateAction(item)}}/>
                  )}
                  <span className={styles["info"]}>{item.title}</span>
                </div>
                <span style={{fontStyle: 'italic', color: '#808080', textTransform: 'uppercase', fontSize: 14}}>{item.timestamp?.toLocaleDateString()}, {item.timestamp?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</span>
              </div>
              {item.type == "SUPPORT_TIME" && (
                <span style={{fontStyle: 'italic', color: '#808080', fontSize: 14}}>{item?.subtitle}</span>
              )}
            </div>

            <div>
              <div className={styles["divider"]}></div>
              <pre className={styles["info"]}>{item.description!}</pre>
            </div>
          </div>
        </div>
      )
    })
  }


  function IncrementorControl(label: string, displayValue: String, onIncrement: () => void, onDecrement: () => void) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
      <span>{label}</span>
      <div className={styles["time-increment"]}>
        <button onClick={onDecrement}>
          <IoRemoveCircle size={20} />
        </button>
        <span>{displayValue}</span>
        <button onClick={onIncrement}>
          <IoAddCircle size={20} />
        </button>
      </div>
    </div>;
  }

  return (
    <div className={styles['content']} style={{display: 'flex', flexDirection: 'row', gap: 12, height: '100%'}}>
      <div style={{width: '33%'}}>
        <Tabs tabs={[
          {
          label: "Alert Details",
          content:
          <>
           <div className={styles['alertDetails']} style={{gap: 6, marginBottom: 76}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', visibility: (details?.status == 'CLOSED' ) ? 'hidden' : undefined}}>
              <div style={{display: 'flex', gap: 24}}>
                {details?.status != 'CLOSED' ?
                  <button className={styles["perentie-button"]} onClick={showAssignClick}>
                    <span>Assign</span>
                  </button> : []
                }
                {details?.handlingUser?.objectId == currentUser.objectId ? (
                  <button className={styles["perentie-button"]} onClick={() => alertHandle('unhandle')}>
                    <span>Unhandle</span>
                  </button>
                ) : (
                  canAssign ? (
                    <button className={styles["perentie-button"]} onClick={() => alertHandle('handle')}>
                      <span>Handle</span>
                    </button>
                  ) : (
                    []
                  )
                )}
              </div>
              <div>
                <button style={{visibility: (details?.status == 'CLOSED' ) ? 'hidden' : undefined}} className={styles["perentie-button"]} onClick={showCloseAlertClick}>
                  <span>Close Alert</span>
                </button>
              </div>
            </div>

            {/* Alert Details */}
             <div style={{display: 'flex', flexDirection: 'column'}}>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Status</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{details?.statusDisplay}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Perentie Ref&nbsp;<IoCopyOutline
                     className={`${styles["alert-info-val-clickable"]}`} onClick={() => copyText(details?.reference!)}/></span>
                 </div>
                 <div className={`${styles["alert-info-val"]}`}>
                   <span>{details?.reference}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Client Ref&nbsp;&nbsp;<IoBuildOutline onClick={showAlertReferenceClick}
                                                                      className={`${styles["alert-info-val-clickable"]}`}/>&nbsp;&nbsp;
                   <IoCopyOutline className={`${styles["alert-info-val-clickable"]}`}
                                  onClick={() => copyText(details?.clientReference!)}/></span>
                 </div>
                 <div className={`${styles["alert-info-val"]}`}>
                   <span>{details?.clientReference}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Support Time</span>
                 </div>
                 <div className={`${styles["alert-info-val"]}`}>
                   <span>{timeUtil.formatMinutes(details?.supportMinutes!, "HOURS_AND_MINUTES")}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Component</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{details?.componentName}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Start / End</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{details?.created?.toLocaleString([], {
                     day: '2-digit',
                     month: '2-digit',
                     year: '2-digit',
                     hour: '2-digit',
                     minute: '2-digit'
                   })} / {details?.ended == null ? '~' : details.ended.toLocaleString([], {
                     day: '2-digit',
                     month: '2-digit',
                     year: '2-digit',
                     hour: '2-digit',
                     minute: '2-digit'
                   })}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Duration</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{dur == null ? '~' : dur.roughDuration()}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Rule Triggered</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{details?.lastRule?.name}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Agent</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{details?.agentName}</span>
                 </div>
               </div>
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Handled by</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span>{details?.handlingUser == null ? '~' : details.handlingUser.username}</span>
                 </div>
               </div>
               {details?.status != 'ACTIVE_OPEN' && (
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Close Summary&nbsp;<IoBuildOutline onClick={() => setShowCloseSummary(true)}
                                                      className={`${styles["alert-info-val-clickable"]}`}/></span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <pre>{closeSummary}</pre>
                 </div>
               </div>
                 )}
               <div className={styles["alert-info"]} style={{}}>
                 <div className={styles["alert-info-key"]} style={{}}>
                   <span>Event Groups</span>
                 </div>
                 <div className={styles["alert-info-val"]}>
                   <span style={{display: 'flex', flexDirection: 'column', gap: 2}}>{eventSummary}</span>
                 </div>
               </div>
             </div>
           </div>
            <PerentieModal title='Assign Alert To User' visible={showAssign} onClose={() => setShowAssign(false)}
                           footer={
                             <>
                               {saving ? (
                                 <></>
                               ) : (
                                 <div
                                   style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                                   <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                        onClick={showAssignClose}>
                        <span>Cancel</span>
                      </button>
                      <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}}
                        onClick={confirmAssign}>
                        <span>Assign</span>
                      </button>
                    </div>
                  )}
                </>
              }>
              {saving ? <p>Saving...</p> :
              (
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                  <span>Users</span><br/>
                  <select id={'userSelect'} style={{width: '50%'}} onChange={selectChange} value={selectedUser}>
                    <option>Select User...</option>
                    {selectOpts}
                  </select>
                </div>
              )}
            </PerentieModal>
            <PerentieModal title='Edit Client Reference' visible={showAlertReference} onClose={showAlertReferenceClose}
              footer={
                <>
                  {saving ? (
                    <></>
                  ) : (
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                      <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                        onClick={showAlertReferenceClose}>
                        <span>Cancel</span>
                      </button>
                      <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}}
                        onClick={confirmReference}>
                        <span>Save</span>
                      </button>
                    </div>
                  )}
                </>
              }>
              {saving ?
                <p>Saving...</p>
              : (
                <div>
                  <Label text='Reference'></Label>
                  <textarea rows={2} className={`${styles['textarea']} ${styles["sm"]}`} onChange={(e) => (setReference(e?.target?.value))} defaultValue={details?.clientReference}></textarea>
                </div>
              )}
            </PerentieModal>

            <PerentieModal title='Edit Close Summary' visible={showCloseSummary} onClose={() => setShowCloseSummary(false)}
                           footer={
                             <>
                               {saving ? (
                                 <></>
                               ) : (
                                 <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                                   <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                                           onClick={() => { setCloseSummary(details?.closeSummary ?? ''); setShowCloseSummary(false);}}>
                                     <span>Cancel</span>
                                   </button>
                                   <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}}
                                           onClick={updateCloseSummary}>
                                     <span>Save</span>
                                   </button>
                                 </div>
                               )}
                             </>
                           }>
              {saving ?
                <p>Saving...</p>
                : (
                  <div>
                    <Label text='Close Summary'></Label>
                    <textarea rows={2} className={`${styles['textarea']} ${styles["sm"]}`} onChange={(e) => (setCloseSummary(e?.target?.value))} defaultValue={details?.closeSummary}></textarea>
                  </div>
                )}
            </PerentieModal>

            <PerentieModal height={420} title='Close Alert' visible={showCLoseEventDialog} onClose={showCLoseEventDialogClose}
              footer={
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                  <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                    onClick={showCLoseEventDialogClose}>
                    <span>Cancel</span>
                  </button>
                  <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}}
                    onClick={() => {closeAlert(details!, document.getElementById('closeSummary')?.textContent!)}}>
                    <span>Close</span>
                  </button>
                </div>
              }
              >
                <p>Are you sure you want to close this alert? This cannot be undone.</p>
                <br/>
                <label>Close Summary</label>
                <textarea rows={8} className={`${styles['textarea']} ${styles['sm']}`} id={'closeSummary'} defaultValue={closeSummary} onChange={(e) => setCloseSummary(e.target.value)}></textarea>
              </PerentieModal>
          </>,
          contentStyle: {overflow: 'auto', height: '100%'}
        },
        ]} />
      </div>
      {details && (
        <div style={{width: '66%'}}>
          <Tabs defaultSelected={details?.eventLog?.filter(item => item.type == "COMMENT" || item.type == "SUPPORT_TIME").length! > 0 ? 1 : 0} tabs={[
            // Alert Event Log
            {
              label: "Event Log",
              content:
              <>
                {eventLog.length > 0 ? (
                  <div style={{marginBottom: 76}}>
                    {addedActionEvent == false ? (
                      <Table data={eventLog} renderItem={renderItem} HeaderComponent={<TableHeader />} border />
                    ) : (
                      []
                    )}
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}
              </>,
              contentStyle: {height: '100%', overflow: 'scroll'}
            },

            // Alert Actions
            {
              label: "User Events",
              content:
              <div>
                <div>
                  <div style={{display: "flex", flexDirection: 'column', gap: 12, marginBottom: 76}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <ButtonGroup groups={[
                        {
                          label: "All",
                          icon: <IoGlobeOutline/>,
                          onClick: () => {setActionEventsFilter('')}
                        },
                        {
                          label: "Comments",
                          icon: <IoClipboard/>,
                          onClick: () => {setActionEventsFilter('COMMENT')}
                        },
                        {
                          label: "Support Time",
                          icon: <IoClipboard/>,
                          onClick: () => {setActionEventsFilter('SUPPORT_TIME')}
                        }
                      ]}/>
                      <button className={styles["perentie-button"]} onClick={showActionsClick}>
                        <span>Add Action</span>
                      </button>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                      {ActionEventsList()}
                    </div>
                  </div>
                </div>

                <PerentieModal visible={showSupportTime || showEditSupportTime} title={(showSupportTime ? 'New' : 'Edit') + ' Support Time'} height={370} onClose={showSupportTimeClose} footer={
                  <>
                    {saving ? (
                      <></>
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                        <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                          onClick={showSupportTimeClose}>
                          <span>Cancel</span>
                        </button>
                        <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}} disabled={supportTime.duration == 0 || comment?.trim().length == 0}
                          onClick={() => {confirmAction('SUPPORT_TIME')}}>
                          <span>Save</span>
                        </button>
                      </div>
                    )}
                  </>
                }>
                  {saving ? (
                      <p>Saving...</p>
                    ) : (
                      <Fragment>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                          <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                              {IncrementorControl('Start Date:', supportTime.startTime.toLocaleDateString(), () => supportDateChange(supportTime.startTime, 'increment'), () => supportDateChange(supportTime.startTime, 'decrement'))}
                              {IncrementorControl('Start Time:', supportTime.startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), () => supportTimeChange('start', 'increment'), () => supportTimeChange('start', 'decrement'))}
                              {IncrementorControl('End Time:', supportTime.endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), () => supportTimeChange('end', 'increment'), () => supportTimeChange('end', 'decrement'))}
                            </div>
                            <div style={{display: 'flex', gap: 6, alignContent: 'center'}}>
                              <Label text='Duration:'></Label>
                              <Label text={timeUtil.formatMinutes(((supportTime.endTime.getTime() - supportTime.startTime.getTime())/1000)/60, "HOURS_AND_MINUTES")}></Label>
                            </div>
                          </div>
                          <div>
                            <Label text='Comment'></Label>
                            <textarea rows={1} className={styles['textarea']} onChange={handleNewComment} defaultValue={comment}></textarea>
                          </div>
                        </div>
                      </Fragment>
                    )}
                </PerentieModal>

                <PerentieModal visible={showComment || showEditComment} title={(showComment ? 'New' : 'Edit') + ' Comment'} onClose={showCommentClose} footer={
                  <>
                    {saving ? (
                      <></>
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                        <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                          onClick={showCommentClose}>
                          <span>Cancel</span>
                        </button>
                        <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}} disabled={comment?.trim().length == 0}
                          onClick={() => {confirmAction('COMMENT')}}>
                          <span>Save</span>
                        </button>
                      </div>
                    )}
                  </>
                }>
                  {saving ? (
                    <p>Saving...</p>
                  ) : (
                    <Fragment>
                      <Label text='Comment'></Label>
                      <textarea rows={1} className={styles['textarea']} onChange={handleNewComment} defaultValue={comment}></textarea>
                    </Fragment>
                  )}
                </PerentieModal>

                <PerentieModal title='Actions' height={200} visible={showActions} onClose={showActionsClose}>
                  <div style={{display: 'flex', flexDirection: 'column', gap: 16, marginTop: '12px'}}>
                    {actions.map((action, idx) => {
                      return (
                        <div key={idx}
                          className={styles["action"]} onClick={action.click}>
                          <p>{action.label}</p>
                          <p>{action.icon}</p>
                        </div>
                      )
                    })}
                  </div>
                </PerentieModal>
              </div>,
              contentStyle: {overflow: 'auto', height: '100%'}
            }
          ]}/>
        </div>
      )}
    </div>
  )
}

export default AlertDetailsPage;
